import NavBar from "./NavBar";

const Header = ()=>
{
    return(
        <div className="flex w-full items-center justify-between">
            
            {/* Imagen y nombre */}
            <div className="flex items-center w-2/6 justify-around mt-4">
                <div className="w-20 h-20 bg-slate-50 rounded-full hover:scale-120"></div>
                <h1 className="-translate-x-full text-slate-400 text-2xl self-start cursor-pointer">Nemo</h1>
            </div>

            {/* Barra de navegación */}
            <div className="w-3/5 text-xl">
                <NavBar></NavBar>
            </div>
        </div>
    );
}

export default Header;
