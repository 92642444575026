const NavBar = ()=>
{
    return(
        <div>
            <ul className="flex text-slate-600 justify-around">
                <li className="hover:text-slate-400 transition-all hover:border-b-4 border-cyan-600 cursor-pointer">Home</li>
                <li className="hover:text-slate-400 transition-all hover:border-b-4 border-cyan-600 cursor-pointer">About Me</li>
                <li className="hover:text-slate-400 transition-all hover:border-b-4 border-cyan-600 cursor-pointer">My Projects</li>
            </ul>
        </div>
    );
}

export default NavBar;