import '../css/containerAnimations.css'

const Container = ()=>
{
    return(

        <div className="w-full h-60 mt-8 flex">
            <div className="w-20 h-20 bg-slate-800 ml-6 rounded-md transition-all hover:rotate-180 hover:bg-slate-700 flex justify-center text-7xl font-sans text-orange-400">!</div>
            <div className="w-80 h-20 p-2 transition-all bg-slate-800 ml-6 rounded-md flex items-center text-center text-slate-400 font-sans italic font-bold">I'm a Junior Web Developer. That's my work. And welcome to my spot.</div>
        </div>
    );
}

export default Container;